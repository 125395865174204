<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros <span>Servicios</span></h2>
            <p>Ofrecemos servicios empresariales personalizados para potenciar su crecimiento y éxito</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-img">
                    <img src="assets/img/services-img3.jpg" alt="services-img">
                    <div class="icon">
                        <i class="fas fa-pencil-alt"></i>
                    </div>
                </div>
                <div class="pricingTable">
                    <h3 class="title">SEGURIDAD Y SALUD EN EL TRABAJO</h3>
                    <p>
                            Es fundamental para proteger a 
                            los trabajadores y garantizar la productividad. Previenen accidentes, 
                            enfermedades y promueven ambientes laborales seguros, lo que mejora la moral del personal, 
                            reduce la rotación laboral y contribuye al éxito de las organizaciones</p>
     
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                    </ul>
                    <a target="_blank" href="https://wa.me/573013823341?text=Hola, quiero cotizar Servicios de Seguridad y Salud en el Trabajo" class="btn btn-primary">!Cotiza Ya¡</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-img">
                    <img src="assets/img/services-img2.jpg" alt="services-img">
                    <div class="icon">
                        <i class="fas fa-pencil-alt"></i>
                    </div>
                </div>
                <div class="pricingTable">
                    <h3 class="title">BIENESTAR LABORAL</h3>
                    <p>Es la clave para el éxito empresarial. Mejora la salud y la moral de los empleados, aumenta la productividad y reduce el ausentismo. Además, ayuda a atraer y retener talento, fortalece la cultura corporativa y la reputación de la empresa</p>
                    <br>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                        <li><i class="fas fa-check"></i> Pending</li>
                    </ul>
                    <a target="_blank" href="https://wa.me/573013823341?text=Hola, quiero cotizar Servicios de Bienestar Laboral" class="btn btn-primary">!Cotiza Ya¡</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-img">
                    <img src="assets/img/services-img1.jpg" alt="services-img">
                    <div class="icon">
                        <i class="fas fa-pencil-alt"></i>
                    </div>
                </div>
                <div class="pricingTable">
                    <h3 class="title">SEGURIDAD SOCIAL</h3>
                       <p>
                            Es esencial para proteger a trabajadores independientes, empleados y empresas. 
                            Proporciona acceso a servicios médicos, estabilidad financiera y cumple con obligaciones legales,
                            promoviendo el bienestar y la productividad en la sociedad</p>
          
                    <ul class="pricing-content">
                        <li class="left-align">
                            <div class="d-flex align-items-center justify-content-center">
                                <i class="fas fa-check mr-2"></i> 
                                <b>Afiliación</b>
                            </div>
                            <br>
                            <ul class="text-left">
                                <li>Aplica para Independientes, Empleadores, Empresas y Empleados</li>
                                <li>ARL (Administradora de Riesgos Laborales)</li>
                                <li>EPS (Entidad Promotora de Salud)</li>
                                <li>AFP (Administradora de fondos de pensiones)</li>
                                <li>CCF (Cajas de Compensación Familiar)</li>
                              </ul>                              
                          </li>

                          <li class="left-align">
                            <div class="d-flex align-items-center justify-content-center">
                                <i class="fas fa-check mr-2"></i> 
                                <b>Inclusión de Beneficiarios</b>
                            </div>
                            <br>
                            <ul class="text-left">
                                <li>Aplica para Independientes y Empleados</li>
                                <li>EPS (Entidad Promotora de Salud)</li>
                                <li>EPS- UPC adicional</li>
                                <li>CCF (Cajas de Compensación Familiar) </li>
                              </ul>   
                          </li>

                          <li class="left-align">
                            <div class="d-flex align-items-center justify-content-center">
                                <i class="fas fa-check mr-2"></i> 
                                <b>Radiación y cobro Incapacidades</b>
                            </div>
                            <br>
                            <ul class="text-left">
                                <li>Aplica para Independientes, Empleados</li>
                                <li>Enfermedad general y laboral</li>
                                <li>Licencia de maternidad</li>
                                <li>Licencia de paternidad </li>
                                <li>Validez de incapacidades </li>
                              </ul> 
                          </li>

                          <li class="left-align">
                            <div class="d-flex align-items-center justify-content-center">
                                <i class="fas fa-check mr-2"></i> 
                                <b>Traslados</b>
                            </div>
                            <br>
                            <ul class="text-left">
                                <li>Aplica para Independientes, Empleadores, Empresas y Empleados</li>
                                <li>ARL (Administradora de Riesgos Laborales)</li>
                                <li>EPS (Entidad Promotora de Salud) </li>
                                <li>AFP (Administradora de fondos de pensiones </li>
                                <li>CCF (Cajas de Compensación Familiar) </li>
                              </ul> 
                          </li>

                          <li class="left-align">
                            <div class="d-flex align-items-center justify-content-center">
                                <i class="fas fa-check mr-2"></i> 
                                <b>Certificados de afiliación</b>
                            </div>
                            <ul class="text-left">
                                <li><b></b></li>
                                <li>Aplica para Independientes, Empleadores, Empresas y Empleados</li>
                                <li>ARL (Administradora de Riesgos Laborales)</li>
                                <li>EPS (Entidad Promotora de Salud) </li>
                                <li>AFP (Administradora de fondos de pensiones </li>
                                <li>CCF (Cajas de Compensación Familiar) </li>
                              </ul> 
                            
                          </li>

                          <li class="left-align">
                            <div class="d-flex align-items-center justify-content-center">
                                <i class="fas fa-check mr-2"></i> 
                                <b>Elaboración de planilla y reporte de novedades para el pago de seguridad social</b>
                            </div>
                            <br>
                            <ul class="text-left">
                                <li>Aplica para Independientes, Empleadores, Empresas </li>
                              </ul> 
                          </li>

                          <li class="left-align">
                            <div class="d-flex align-items-center justify-content-center">
                                <i class="fas fa-check mr-2"></i> 
                                <b>Conciliación de deudas en entidades de seguridad social</b>
                            </div>
                            <br>
                            <ul class="text-left">
                                <li>Aplica para Independientes, Empleadores, Empresas </li>
                              </ul> 
                          </li>
                    </ul>
                    <a target="_blank" href="https://wa.me/573013823341?text=Hola, quiero cotizar Servicios de Seguridad Social" class="btn btn-primary">!Cotiza Ya¡</a>
                </div>
            </div>
        </div>
    </div>
</div>