<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
            <!-- Brand -->
        <a class="navbar-brand rounded-circle" href="#">
            <img src="assets/img/logo.png" height="60" alt="Sobiem" loading="lazy" />
        </a>
            
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Precios</span></li> -->
                    <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('team')">Equipo</span></li> -->
                    <li class="nav-item"><a class="nav-link" routerLink="/signin">Login</a></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contactenos</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">Blog</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('faq')">Nuestro Trabajo</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Servicios</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('about')">Sobre Nosotros</span></li>
                    <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
                </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">Sobre Nosotros</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Servicios</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('faq')">Nuestro Trabajo</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('blog')">Blog</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">Contactenos</span></li>
                <li class="nav-item"><a class="nav-link" routerLink="/signin">Login</a></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">Equipo</span></li> -->
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('pricing')">Precios</span></li> -->



            </ul>
        </div>
    </div>
</nav>