<div class="main-banner item-bg-one">
    <div class="creative-banner-three"></div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h1>SOBIEM</h1>
                    <h1> <h1>Expertos en </h1>
                        <ngx-typed-js [strings]="['Seguridad Social', 'Bienestar Laboral', 'Seguridad y Salud en el Trabajo']" [typeSpeed]="20" [loop]="true" [backSpeed]="120" [showCursor]="false">
                            <span class="typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <p>Soluciones de Bienestar Empresarial</p>
                    <a (click)="onClick('services')" class="btn btn-primary">Seguridad y Salud en el Trabajo</a>
                    <a (click)="onClick('services')" class="btn btn-primary">Bienestar Laboral</a>
                    <a (click)="onClick('services')" class="btn btn-primary">Seguridad Social</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-about></app-about>

<app-who-we-are></app-who-we-are>

<app-funfacts></app-funfacts>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<!-- <app-work></app-work> -->


<app-faq></app-faq>
<!-- 
<app-feedback></app-feedback> -->

<app-blog></app-blog>

<app-partner></app-partner>


<app-contact></app-contact>